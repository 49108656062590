<template>
  <div class="visit-replay-edit" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
    <van-row type="flex" justify="center" align="center" class="card">
      <van-col span="18">
        <van-field style="border-radius: 6px 0 0 0;" label="标题" :value="cardInfo.title"></van-field>
        <van-field label="计划出发时间" :value="cardInfo.startTime"></van-field>
        <van-field style="" label="计划返回时间" :value="cardInfo.endTime"></van-field>
        <van-field style="border-radius: 0 0 0 6px;" label="走访类型" :value="cardInfo.type"></van-field>
      </van-col>
      <van-col span="6">
        <van-row>
          <van-icon name="info-o"/>
        </van-row>
        <van-row>
          <button style="background-color: #378FF2; border:none" :disabled="flag" @click="submit()">推送审核</button>
        </van-row>
      </van-col>

    </van-row>
    <van-row>
      <van-notice-bar v-if="hidden">
        全部填写完毕后，请点击推送审核，进行审批
      </van-notice-bar>
    </van-row>
    <div class="list" v-for="(item,index) in list" v-bind:key="index">
      <van-row type="flex" justify="start" align="center">
        <van-col span="20">
          <van-row class="list-title">
            {{ item.customerName }}
          </van-row>
          <van-row>
            <van-col class="list-info" span="4">{{ item.createdPeople }}</van-col>
            <van-col class="list-info" span="6">{{ item.enterpriseNature }}</van-col>
            <van-col class="list-info" span="6">{{ item.enterpriseType }}</van-col>
            <van-col class="list-info" span="8">{{ item.group }}</van-col>
          </van-row>
          <van-row class="list-info">
            {{ item.addressDetailed }}
          </van-row>
        </van-col>
        <van-col span="4" @click="to(item.to)">
          <van-icon :name='item.status == "未批复" ? "edit":"eye-o" '/>
        </van-col>
      </van-row>
    </div>
    <crm-tab-bar :tabpage="3"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import {Toast} from "vant";

export default {
  name: "BulkCrmsVisitReplayEdit",
  components: {CrmTabBar},
  data() {
    return {
      flag: false,
      cardInfo: {
        startTime: '',
        endTime: '',
        title: '',
        type: 'zoufang ',
      },
      list: [],
      freshHeight: 0,
      hidden: false
    }
  },
  mounted() {
    this.freshHeight = 2500
    this.init()
  },
  methods: {
    to(to) {
      this.$router.push(to);
    },
    init() {
      this.$ajax.post('/api/ajax/replay/getBulkCrmReplayList.json',
          {
            titleId: this.$route.params.id,
            createdPeople: this.$store.getters.userId,
            replayId: '',
            titleName: '',
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          if (res.data.length > 0) {
            this.list = res.data
            this.cardInfo.title = res.data[0].titleName
            this.cardInfo.startTime = res.data[0].plandateStart
            this.cardInfo.endTime = res.data[0].plandateEnd
            this.cardInfo.type = res.data[0].travelType == '002' ? '电话回访' : res.data[0].travelType == '004' ? '客户来访' : '走访拜访'
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].to = '/BulkCrmsVisitReplayDetailEdit/' + this.list[i].replayId
            }
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
      setTimeout(() => {
        this.hidden = true
      }, 1500);
    },
    submit() {
      this.flag = true
      console.log(this.list)
      if (this.list[0].status == '未批复') {
        var customer = ''
        for (let i = 0; i < this.list.length; i++) {
          if ((this.list[i].realContact.length == 0) || this.list[i].pushResult.length == 0 || this.list[i].businessVolume.length == 0 || this.list[i].profitSituation.length == 0) {
            customer = this.list[i].customerName
            break;
          }
        }
        if (customer.length == 0) {
          Toast.loading({
            message: '推送中...',
            forbidClick: true,
            loadingType: 'spinner'
          });
          //走访复盘推送前，先找关系表是否有该负责人信息，有则不变，无则添加
          this.$ajax.post('/api/ajax/replay/getRelationToAdd.json',
              {
                titleId: this.list[0].titleId,
                createdPeople: this.$store.getters.userId
              }).then(res => {
            //走访复盘推送审核(如果问题不为无，还需添加问题表及推送)
            this.$ajax.post('/api/ajax/replay/updateBulkCrmReplayStatus.json',
                {
                  titleId: this.list[0].titleId,
                  createdPeople: this.$store.getters.userId
                }).then(res => {
              if (res.status == 200) {
                Toast.success('推送成功');
                //重新初始化查询
                setTimeout(() => {
                  this.$router.push({path: '/BulkCrmsVisitReplayList'})
                }, 200);
              }
            }).catch(err => {
              console.log(err)
              this.$message.error('由于您没有该客户的拜访维护权限，请联系运营中心申请！');
            })
          }).catch(err => {
            console.log(err)
            this.$message.error('由于您没有该客户的拜访维护权限，请联系运营中心申请！');
          })

        } else {
          this.$message.info('请补全' + customer + '！');
        }
      } else {
        this.$message.info('不允许重复推送！');
      }
    },
  }
}
</script>

<style scoped>
.visit-replay-edit {
  background: #f3f3f3;
}

.card {
  margin: 10px 5px;
  background: #378FF2;
  border-radius: 6px;
  box-shadow: 6px 6px 5px #888888;
}

.list {
  background: #FFFFFF;
  padding: 5px 10px;
  margin-top: 5px;
}

.list-title {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  font-style: normal;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
}

.list-info {
  padding-top: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
}
</style>